import React from 'react';
import Navigation from '../components/Navigation';
import PhotoAlbum from "react-photo-album";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import { useTranslation } from 'react-i18next';
import OutdoorGrillSharpIcon from '@mui/icons-material/OutdoorGrillSharp';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Icon from '@mui/material/Icon';
import Footer from '../components/Footer';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import CastleIcon from '@mui/icons-material/Castle';

const photos = [
    { src: '/images/arroundings/mini/bonaguil.jpg', width: 800, height: 600 },
    { src: '/images/arroundings/mini/cahors.jpg', width: 800, height: 600 },
    { src: '/images/arroundings/mini/chateauFumel.jpg', width: 800, height: 600 },
    { src: '/images/arroundings/mini/lot.jpg', width: 800, height: 600 },
    { src: '/images/arroundings/mini/monflanquin.jpg', width: 800, height: 600 },
    { src: '/images/arroundings/mini/montcuq.jpg', width: 800, height: 600 },
    { src: '/images/arroundings/mini/penne.jpg', width: 800, height: 600 },
    { src: '/images/arroundings/mini/villereal.jpg', width: 800, height: 600 },
];

const lightBoxPhotos = [
    { src: '/images/arroundings/bonaguil.jpg' },
    { src: '/images/arroundings/cahors.jpg' },
    { src: '/images/arroundings/chateauFumel.jpg' },
    { src: '/images/arroundings/lot.jpg' },
    { src: '/images/arroundings/monflanquin.jpg' },
    { src: '/images/arroundings/montcuq.jpg' },
    { src: '/images/arroundings/penne.jpg' },
    { src: '/images/arroundings/villereal.jpg' },
];

const Arroundings = () => {
    const { t } = useTranslation();
    const equipmentList = [
        { name: t('cottage.equipment.barbecue'), icon: <OutdoorGrillSharpIcon /> },
    ];
    const [index, setIndex] = React.useState(-1);
    const placesToSee = [
        { name: "Cahors", icon: <LocationCityIcon /> },
        { name: t('arroundings.bonaguil'), icon: <CastleIcon /> },
        { name: t('arroundings.fumel'), icon: <CastleIcon /> },
        { name: "Monflanquin", icon: <LocationCityIcon /> },
        { name: "Montcuq", icon: <LocationCityIcon /> },
        { name: "Penne d'Agennais", icon: <LocationCityIcon /> },
        { name: "Villereal", icon: <LocationCityIcon /> },
    ];
    return (
        <div id='content-container'>
            <Navigation />
            <main>
                <div className='main-content'>
                    <PhotoAlbum layout="rows" spacing="0" photos={photos} onClick={({ index: current }) => setIndex(current)} />
                    <Lightbox
                        index={index}
                        slides={lightBoxPhotos}
                        open={index >= 0}
                        close={() => setIndex(-1)}
                    />
                    <h2>{t("arroundings.title1")}</h2>
                    <p className='description'>{t("arroundings.description")}</p>
                    <div className='div-align'>
                        <h3>{t('arroundings.ideas')}</h3>
                        <List>
                            {placesToSee.map((place, index) => (
                                <ListItem key={index}>
                                    <ListItemIcon>
                                        <Icon>{place.icon}</Icon>
                                    </ListItemIcon>
                                    <ListItemText primary={place.name} />
                                </ListItem>
                            ))}
                        </List>
                    </div>
                </div>
            </main>
            <Footer />
        </div>

    );
};

export default Arroundings;