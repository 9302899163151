import React from 'react';
import Navigation from '../components/Navigation';
import Equipment from '../components/Equipments';
import PhotoAlbum from "react-photo-album";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import { useTranslation } from 'react-i18next';
import OutdoorGrillSharpIcon from '@mui/icons-material/OutdoorGrillSharp';
import Footer from '../components/Footer';

const photos = [
    { src: '/images/village/mini/village1.jpg', width: 800, height: 600 },
    { src: '/images/village/mini/piscine.jpg', width: 800, height: 600 },
    { src: '/images/village/mini/aire_de_jeux.jpg', width: 800, height: 600 },
];

const lightBoxPhotos = [
    { src: '/images/village/village1.jpg' },
    { src: '/images/village/piscine.jpg' },
    { src: '/images/village/aire_de_jeux.jpg' },
    { src: '/images/village/piscine2.jpg' },
    { src: '/images/village/piscine3.jpg' },

];



const Village = () => {
    const { t } = useTranslation();
    const equipmentList = [
        { name: t('cottage.equipment.barbecue'), icon: <OutdoorGrillSharpIcon /> },
    ];
    const [index, setIndex] = React.useState(-1);
    return (
        <div id='content-container'>
            <Navigation />
            <main>

                <div className='main-content'>
                    <PhotoAlbum layout="rows" spacing="0" photos={photos} onClick={({ index: current }) => setIndex(current)} />
                    <Lightbox
                        index={index}
                        slides={lightBoxPhotos}
                        open={index >= 0}
                        close={() => setIndex(-1)}
                    />
                    <h2>{t("village.title1")}</h2>
                    <p className='description'>{t("village.description")}
                    </p>
                    <h3>{t("village.title2")}</h3>
                    <Equipment />
                </div>

            </main>
            <Footer />
        </div>

    );
};

export default Village;