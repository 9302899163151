import React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Icon from '@mui/material/Icon';
import { useTranslation } from 'react-i18next';
import PoolSharpIcon from '@mui/icons-material/PoolSharp';
import SportsVolleyballSharpIcon from '@mui/icons-material/SportsVolleyballSharp';
import GrassSharpIcon from '@mui/icons-material/GrassSharp';
import { SportsTennisSharp } from '@mui/icons-material';

const Equipment = () => {
    const { t } = useTranslation();
    const equipmentList = [
        { name: t("equipments.swimming-pool"), icon: <PoolSharpIcon /> },
        { name: t("equipments.playground"), icon: <GrassSharpIcon /> },
        { name: t("equipments.volley"), icon: <SportsVolleyballSharpIcon /> },
        { name: t("equipments.tennis"), icon: <SportsTennisSharp /> },
    ];
    return (
        // Affichage des cartes d'équipements
        <div>
            <List>
                {equipmentList.map((equipment, index) => (
                    <ListItem key={index}>
                        <ListItemIcon>
                            <Icon>{equipment.icon}</Icon>
                        </ListItemIcon>
                        <ListItemText primary={equipment.name} />
                    </ListItem>
                ))}
            </List>
        </div>
    );
}

export default Equipment;
