import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import './../styles/contact.scss';
import { useTranslation } from 'react-i18next';

const ContactForm = () => {
    const form = useRef();
    const { t } = useTranslation();
    const sendEmail = (e) => {
        e.preventDefault();
        emailjs.sendForm(process.env.REACT_APP_SERVICE_ID, process.env.REACT_APP_TEMPLATE_ID, form.current, process.env.REACT_APP_PUBLIC_KEY)
            .then((result) => {
                alert('message sent successfully...');
                console.log(result.text);
            },
                (error) => { console.log(error.text); });
    };
    return (
        <div>
            <form className="contact-form" ref={form} onSubmit={sendEmail}>
                <h2>{t("contact.title")}</h2>
                <div>
                    <label htmlFor="name">{t("contact.name")}*</label>
                    <input name="from_name" type="text" id="name" required />
                </div>
                <div>
                    <label htmlFor="email">{t("contact.email")}*</label>
                    <input name="from_mail" type="email" id="email" required />
                </div>
                <div>
                    <label htmlFor="message">{t("contact.message")}</label>
                    <textarea name="message" id="message" required />
                </div>
                <button type="submit">{t("contact.btn_validate")} </button>
            </form>
        </div >
    );
};

export default ContactForm;