import React from 'react';
import ContactForm from '../components/ContactForm';
import Navigation from '../components/Navigation';
import Footer from '../components/Footer';
import { useTranslation } from 'react-i18next';

const Contact = () => {
    const { t } = useTranslation();
    return (
        <div id='content-container'>
            <Navigation />
            <main>
                <div className='main-content'>
                    <ContactForm />
                    <div className='description contact' >
                        <span>{t('contact.description')} </span><br />
                        <span>{t('contact.price')} </span>
                    </div>
                </div>
            </main>
            <Footer />
        </div>

    );
};

export default Contact;