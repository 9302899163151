import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Chalet from './pages/Chalet';
import { useTranslation } from "react-i18next";
import Village from './pages/Village';
import Contact from './pages/Contact';
import Arroundings from './pages/Arroundings';
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBjP56VS7WyuKQz8soGl2aDAL1ou1AAuKA",
  authDomain: "chalet-mauroux.firebaseapp.com",
  projectId: "chalet-mauroux",
  storageBucket: "chalet-mauroux.appspot.com",
  messagingSenderId: "856101612005",
  appId: "1:856101612005:web:cdad059ca0b71f3817670c",
  measurementId: "G-NEZJFX4NMC"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);


const App = () => {
  const { t } = useTranslation();
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Chalet />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/chalet" element={<Chalet />} />
        <Route path="/village" element={<Village />} />
        <Route path="/arroundings" element={<Arroundings />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;