import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import { Stack } from '@mui/system';
import Flag from 'react-world-flags'
import { IconButton, ToggleButton, ToggleButtonGroup } from '@mui/material';

const LanguageSwitcher = () => {
    const { i18n } = useTranslation();
    const [language, setLanguage] = useState("fr");

    const handleChange = (event, newLanguage) => {
        setLanguage(newLanguage);
        changeLanguage(newLanguage)
    };

    const changeLanguage = async (language) => {
        await i18n.changeLanguage(language); // i18n.changeLanguage retourne une Promise
    };

    const control = {
        value: language,
        onChange: handleChange,
        exclusive: true,
    };

    return (
        <Stack spacing={2} alignItems="center">
            <ToggleButtonGroup {...control} size="small" aria-label="Small sizes">
                <ToggleButton value="fr" key="fr">
                    <Flag height="16" code="FR" fallback={<span>Unknown</span>} />
                </ToggleButton>,
                <ToggleButton value="en" key="en">
                    <Flag height="16" code="GB" fallback={<span>Unknown</span>} />
                </ToggleButton>
            </ToggleButtonGroup>
        </Stack>
    );
}

export default LanguageSwitcher;