import React from 'react';
import Navigation from '../components/Navigation';
import "../styles/index.scss";
import Lightbox from "yet-another-react-lightbox";
import PhotoAlbum from "react-photo-album";
import "yet-another-react-lightbox/styles.css";
import { useTranslation } from 'react-i18next';
import OutdoorGrillSharpIcon from '@mui/icons-material/OutdoorGrillSharp';
import MicrowaveSharpIcon from '@mui/icons-material/MicrowaveSharp';
import FlatwareSharpIcon from '@mui/icons-material/FlatwareSharp';
import WifiSharpIcon from '@mui/icons-material/WifiSharp';
import TvSharpIcon from '@mui/icons-material/TvSharp';
import BedSharpIcon from '@mui/icons-material/BedSharp';
import ExtensionRoundedIcon from '@mui/icons-material/ExtensionRounded';
import SportsTennisRoundedIcon from '@mui/icons-material/SportsTennisRounded';
import SportsSoccerRoundedIcon from '@mui/icons-material/SportsSoccerRounded';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Icon from '@mui/material/Icon';
import Banner from '../components/Banner';
import Footer from '../components/Footer';

const photos = [
    { src: '/images/chalet/mini/4.jpg', width: 400, height: 300 },
    { src: '/images/chalet/mini/1.jpg', width: 400, height: 300 },
    { src: '/images/chalet/mini/chalet3.jpg', width: 400, height: 300 },
    { src: '/images/chalet/mini/chien_accepte.jpg', width: 600, height: 800 },
];

const lightBoxPhotos = [
    { src: '/images/chalet/4.jpg' },
    { src: '/images/chalet/1.jpg' },
    { src: '/images/chalet/chalet3.jpg' },
    { src: '/images/chalet/chien_accepte.jpg' },
    { src: '/images/chalet/3.jpg' },
    { src: '/images/chalet/chalet1.jpg' },
    { src: '/images/chalet/chalet2.jpg' },
    { src: '/images/chalet/chalet4.jpg' },
    { src: '/images/chalet/chalet5.jpg' },
    { src: '/images/chalet/chalet7.jpg' },
    { src: '/images/chalet/chalet8.jpg' },
    { src: '/images/chalet/chambre_enfants.jpg' },
    { src: '/images/chalet/chambre_parental.jpg' },
    { src: '/images/chalet/chambre_parental2.jpg' },
    { src: '/images/chalet/chambre1.jpg' },
    { src: '/images/chalet/chambre2.jpg' },
    { src: '/images/chalet/chambre3.jpg' },
    { src: '/images/chalet/chambre3b.jpg' },
    { src: '/images/chalet/couloir.jpg' },
    { src: '/images/chalet/raclette.jpg' },
    { src: '/images/chalet/sdb.jpg' },
    { src: '/images/chalet/terrasse.jpg' },
];



const Chalet = () => {
    const { t } = useTranslation();
    const indoorEquipments = [
        { name: t('cottage.equipments.indoor.bedding'), icon: <BedSharpIcon /> },
        { name: t('cottage.equipments.indoor.home_appliance'), icon: <MicrowaveSharpIcon /> },
        { name: t('cottage.equipments.indoor.first_necessity'), icon: <FlatwareSharpIcon /> },
        { name: t('cottage.equipments.indoor.tv'), icon: <TvSharpIcon /> },
        { name: t('cottage.equipments.indoor.wifi'), icon: <WifiSharpIcon /> },
        { name: t('cottage.equipments.indoor.board_games'), icon: <ExtensionRoundedIcon /> },
    ];
    const outdoorEquipments = [
        { name: t('cottage.equipments.outdoor.barbecue'), icon: <OutdoorGrillSharpIcon /> },
        { name: t('cottage.equipments.outdoor.rackets'), icon: <SportsTennisRoundedIcon /> },
        { name: t('cottage.equipments.outdoor.balls'), icon: <SportsSoccerRoundedIcon /> },
    ];
    const [index, setIndex] = React.useState(-1);
    return (
        <div id='content-container'>
            <Navigation />

            <main>

                <div className='main-content'>
                    <PhotoAlbum layout="rows" spacing="0" photos={photos} onClick={({ index: current }) => setIndex(current)} />
                    <Lightbox
                        index={index}
                        slides={lightBoxPhotos}
                        open={index >= 0}
                        close={() => setIndex(-1)}
                    />
                    <h2>{t("cottage.title1")}</h2>
                    <p className='description'> {t('cottage.description')}</p>
                    <div className='div-container'>
                        <div className='div-align'>
                            <h3>{t("cottage.equipments.indoor.title")}</h3>
                            <List>
                                {indoorEquipments.map((equipment, index) => (
                                    <ListItem key={index}>
                                        <ListItemIcon>
                                            <Icon>{equipment.icon}</Icon>
                                        </ListItemIcon>
                                        <ListItemText primary={equipment.name} />
                                    </ListItem>
                                ))}
                            </List>
                        </div>
                        <div className='div-align'>
                            <h3>{t("cottage.equipments.outdoor.title")}</h3>
                            <List>
                                {outdoorEquipments.map((equipment, index) => (
                                    <ListItem key={index}>
                                        <ListItemIcon>
                                            <Icon>{equipment.icon}</Icon>
                                        </ListItemIcon>
                                        <ListItemText primary={equipment.name} />
                                    </ListItem>
                                ))}
                            </List>
                        </div>
                    </div>
                </div>
            </main>
            <Footer />
        </div>
    );
};

export default Chalet;