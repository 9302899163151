import React from 'react';
import { NavLink } from 'react-router-dom';
import LanguageSwitcher from './LanguageSwitcher';
import { useTranslation } from 'react-i18next';


const Navigation = () => {
    const { t } = useTranslation();
    return (
        <header>
            <title>Sherlock's Home : Chalet de vacances dans le Lot</title>
            <nav>
                <div className='navbar-content'>
                    <ul className="navbar">
                        <LanguageSwitcher />
                        <li><NavLink to="/chalet">{t('menu.cottage')}</NavLink></li>
                        <li><NavLink to="/village">{t('menu.village')}</NavLink></li>
                        <li><NavLink to="/arroundings">{t('menu.arroundings')}</NavLink></li>
                        <li><NavLink to="/contact">{t('menu.contact')}</NavLink></li>
                    </ul>
                </div>
            </nav>
        </header>
    );
};

export default Navigation;