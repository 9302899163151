import React from 'react';
import { useTranslation } from 'react-i18next';


const Footer = () => {
    const { t } = useTranslation();
    return (
        <footer>
            <p>© 2023 chalet-mauroux.com - Tous droits réservés</p>
            <a href="https://airbnb.fr/h/chalet-mauroux">{t('footer.airbnb')}<img src="/images/airbnb.png" id="icone-airbnb" /></a>
        </footer>
    );
};

export default Footer;